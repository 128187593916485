#verificationTxt{
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 120%;
    text-align: center;
    color: #404040;
    margin-top: 20px;
}
#OTPTxt{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #757575;
}
.styles_react-code-input__CRulA > input{
    margin: 5px;
    border: 1px solid #f5f5f5 !important;
    background-color: #f5f5f5;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    border-radius: 12px !important;
}
#noOTP{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #616161;
}
#ResendOTP{
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #18498B;
    margin-left: 10px;
}
#Timer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* padding: 8px 24px; */
    margin: 20px;
    width: 79px;
    height: 34px;
    background: #F4F2FF;
    border-radius: 4px;
}
#NextBtn{
    width: 116px;
    height: 40px;
    margin: 10px;
    margin-top: 30px;
    background: #18498B;
    border-radius: 12px;
}
#NextBtn:hover{
    transition: .4s ease-out; 
    background: #0E2D54 !important;
}
#TimerTxt{
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #18498B;
}
.styles_react-code-input-container__tpiKG{
    width: auto !important;
}


@media only screen and (max-width: 550px) {
    #verificationTxt{
        font-size: 24px;
    }
    #OTPTxt{
        font-size: 14px;
    }
}