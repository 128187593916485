.admin_user_info_container {
    background: #FAFAFA;
}

.admin_personal_info {
    background: #FFFFFF;
    max-width: 1162px;
    max-height: auto;
}

.content_title {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    width: 250px;
    height: 36px;
    left: 170px;
    top: 198px;
}

hr {
    border: 1px solid #dbdbdb
}


.admin_avatar_img {
    width: 120px !important;
    height: 120px !important;
    position: relative;
}


.admin_avatar_img img {
    width: 100%;
    height: 80%;
    border-radius: 28%;
    /* margin: 30px 80px 0 0; */
}

.admin_avatar_img .avatar_edit_button button {
    position: absolute;
    right: -7px;
    bottom: 0px;
    width: 34px;
    height: 34px;
    background: #3A3A3A;
    border-radius: 50%;
    text-align: center;
    padding: 4px;
    border: 0;
    outline: 0;
}
.admin_avatar_img .avatar_edit_button button i {
    width: 17px;
    height: 17px;
    color: #FFFFFF;
} 



.admin_user_form{
    margin-top: 26px;
}

.admin_inputs_group {
    margin-right: 80px;
    margin-bottom: 16px;
}

.admin_user_form form label {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #230505;
    text-align: left !important;
    padding: 0 !important;
    margin-bottom: 12px;
}

.first_name input, .spiritual_name input, .middle_name input, .birth_date input, .last_name input, .user_email select, .user_phone input, .user_state select, .user_city select, .user_zipCode select, .user_country select {
    max-width: 690px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #8C8C8C;
}
textarea.form-control {
    max-width: 563px;
}

.gender_info input {
    margin-right: 4px;
}

.male_gender, .female_gender {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #262626;
}

.street_address input, .address_optional input {
    max-width: 690px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #8C8C8C;
}
.inner_admin_avatar_area{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    padding: 24px;
    flex-wrap: wrap;
}
.admin_avatar_parent{
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_avatar_text {
    margin-left: 20px;
}
.admin_avatar_text h3{
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #230505;
}
.admin_avatar_text p{
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #919191;
}
.admin_avatar_text button{
    padding: 5px 12px;
    color: white;
    background: #EEA02B;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    outline: none;
    border: none;
}

.admin_progress{
    width: 35%;
    background: #FAFAFA;
    border-radius: 4px;
    padding: 10px 10px 0 10px;
}
.progress {
    height: 10px;
}
.progress_point{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.progress_point p, h6{
    font-weight: 400;
    font-size: 12px;
    color: #230505;
    margin-top: 10px;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
    .admin_progress{
        width: 100%;
        background: #FAFAFA;
        border-radius: 4px;
        padding: 10px 10px 0 10px;
        margin-top: 15px;
    }
    .admin_avatar_text p {
        font-size: 11px;
        line-height: 15px;
         margin-bottom: 7px; 
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .admin_progress{
        width: 100%;
        background: #FAFAFA;
        border-radius: 4px;
        padding: 10px 10px 0 10px;
        margin-top: 15px;
    }
}


.instPro_CloseBtn {
    background: #E6F0FF !important;
    color: #18498B !important;
    border-radius: 2px;
    border: 0;
    width: 156px;
    height: 40px;
    font-weight: 700;
    font-size: 14px;
}