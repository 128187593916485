.description_area {
    max-height: 173px;
}

.course_title {
    color: #333333;
    font-weight: 400;
    font-size: 14px;
}

.images_group {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.img_box {
    max-width: 137px;
    max-height: 96px;
    background: #F9F9F9;
    border: 1px dashed #D9D9D9;
    border-radius: 2px;
    text-align: center;
    padding: 5px;
    margin-right: 15px;
    cursor: pointer;
}

.img_box span, p {
    color: #8C8C8C;
}

.contentPanel  {
    margin-top: 24px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
}

.add_button {
    background: #18498B;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 700;
    width: 156px;
    height: 40px;
    margin: 5px;
}

.cancel_button {
    background: #E6F0FF;
    border-radius: 2px;
    color: #18498B;
    font-size: 14px;
    font-weight: 700;
    width: 156px;
    height: 40px;
    margin: 5px;
}

.added_imgBox {
    position: relative;
}

.imgBox_icons {
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%);
    
}

.imgBox_icons span {
   color: #bbbbbb;
}

.imgBox_icons span:hover {
    color: #FFFFFF;
}
.ins_select_height {
    height: 40px !important;
    padding-left: 11px !important;
    font-size: 16px !important;
    color: #6c757d !important;
}