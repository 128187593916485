.inner_spiritual{
    font-family: 'Poppins', sans-serif;
    max-width: 940px;
    width: 100%;
    height: 80vh;
    margin: 0 auto;
    overflow-y: scroll;
   padding: 40px 0;
   background: #FFFFFF;
}
.Spiritual_paragraph p {
  color: #070707;
}

.aboutSpiritual h2{
    font-family: 'Poppins', italic;
    font-weight: 600;
    text-align: center;
    margin-bottom: 25px;
}
.aboutSpiritual{
    width: 100%;
    max-width: 770px;
    margin:  auto;
    padding: 0 15px;
    color: #030303;
}
.aboutSpiritual p span{
   font-weight: 600;
}
.check_spiritual label{
    font-weight: 300;
    margin-left: 5px;
}

.check_spiritual {
  display: flex;
}

.check_spiritual input {
  width: 15px;
  margin-top: 5px;
}

/* =====================
    customize scrollbar
 =======================*/
 /* width */
 ::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb{
    border-radius: 40px;
  }
  .inner_spiritual .aboutSpiritual:hover::-webkit-scrollbar-thumb {
    background: #d1d2d3;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #E2E4E7; 
  }

  /* Media query for responsive small devices */
  @media only screen and (max-width: 420px) {
    .spiritual_back{
        margin: 0 15px;
    }
    .spiritual_next{
       margin: 15px 0;
    }
  }