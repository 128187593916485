.messages {
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    /* max-width: 764px; */
    max-height: 718px;
    padding: 15px;
    /* margin: 2vw; */
}

.messageWindowHeader{
    display: flex;
    align-items: center;
 }

.messageWindowHeader img{
    max-width: 60px; 
    max-height: 60px; 
    border-radius: 30px;
}

.messageWindowHeader h5{
    font-weight: 700;
    font-size: 16px;
    margin-left: 10px;
}


.myMessage{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
 }

 .chatting {
    padding-left: 15px;
 }
 .chatting p{
    border-radius: 3px;
    background-color: #E5F0FF;
    padding: 10px;
    border-radius: 25px;
 }

 .chatting_time {
    font-weight: 400;
    font-size: 11px;
    color: #595959;
    padding-left: 5px;
 }

 .myMessage img{
    border-radius: 30px;
    max-width: 60px;
    max-height: 60px;
 }

 .otherMessage{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
 }

 .otherMessage img{
    border-radius: 30px;
    max-width: 60px;
    max-height: 60px;
 }

 .others_chatting {
    padding-right: 15px;
 }

 .others_chatting p{
    border-radius: 3px;
    background-color:  #F7F7F7;
    padding: 10px;
    border-radius: 25px;
 }

 .chatting_time2 {
    font-weight: 400;
    font-size: 11px;
    color: #595959;
    padding-right: 5px;
 }




 .messageWindowFooter input {
    border: 1px solid #BFBFBF;
    border-radius: 4px;
    padding: 5px;
 }

 .messageWindowFooter .send_btn {
    margin-left: 5px;
    background: #1890FF;
    border-radius: 2px;
    max-width: 90px;
    max-height: 40px;
 }


 @media only screen and (max-width: 480px) {

    .messageWindowHeader img{
        max-width: 30px;
        max-height: 30px;
    }

    .myMessage img,  .otherMessage img{
        max-width: 30px;
        max-height: 30px;
     }

     .chatting p, .others_chatting p{
        font-size: 12px;
        font-weight: 400;
     }

     .chatting_time, .chatting_time2 {
        font-size: 10px;
     }

 }