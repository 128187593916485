
.AdminDashboardContentPanel{
    background: #FFFFFF;
    margin-top: 26px;
    height: auto;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    .userListBtn_parent {
      padding: 15px 0 0 17px;
      #user_list_btn{
         background: #FFFFFF;
         border: 1px solid #D9D9D9;
         box-sizing: border-box;
         border-radius: 2px 2px 0px 0px;
         padding: 8px 16px;
         font-weight: 600;
         font-size: 16px;
         color: #262626;
   
       }
  }
    .history_table{
       max-width: 815px;
       margin-top: 20px;
 
       @media (max-width: 992px) { 
          max-width: 715px;
        }
       @media (max-width: 768px) { 
          max-width: 615px;
        }
       @media (max-width: 600px) { 
          max-width: 515px;
        }
 
       table{
          margin-left: 15px;
          margin-bottom: 20px;
          width: 100%;
          thead{
             text-align: center;
             background: #182b88;
             max-width: 200px;
             max-height: 54px;
             
             th{
                border-right: solid 1px #E6F0FF;
                background-color: #18498B;
                color: white;
             }
           }
       }
    }
 }
 
 .pagination {
    display: flex;
    justify-content: flex-end;
 
      // Medium devices (tablets, 768px and up)
      @media (max-width: 768px) { 
         display: flex;
       flex-wrap: wrap;
 
          p, span {
             font-size: 12px;
          }
       }
 }
 
 .historyAddress{
    margin-left: 15px;
    h5 {
       
       font-weight: 700;
       font-size: 16px;
    }
    p {
       font-weight: 400;
       font-size: 12px;
    }
 }
 
 .table_btn{
    background: #30BCED;
    border-radius: 4px;
    color: white;
 }
 
 
 [data-colindex="9"] div{
    background: #30BCED;
    border-radius: 4px;
    padding: 4px 6px;
    color: white;
    cursor: pointer;
  }
  .AdminDashboard_parent_col{
    position: relative;
    #time_after{
       &::after{
          content: "";
          background-color: #DDDDDD;
          position: absolute;
          width: 1px;
          height: 110px;
          left: -24px;
       }
    }
 }
 
 .inner_AdminDashboard{
   form {
      margin: 15px 0;
      background: #FFFFFF;
      padding: 20px 0;
      background: #FFFFFF;
      box-shadow: 0px 2px 4px rgba(217, 217, 217, 0.5);
      border-radius: 5px;

      .adminDashboard_selectTitle {
         font-weight: 400;
         font-size: 14px;
      }

      .placeholder_resize {
         font-weight: 300;
         font-size: 12px;
         cursor: pointer;
      }
     #adminFormBtn{
      padding: 8px 45px;
         background: #09BC8A;
         border-radius: 2px;
         border: none;
         outline: none;
         color: white;
         margin-top: 20px;
         margin-left: 12px;
     }
  }
   
     .AdminDashboard_parent{
         display: flex;
           
         h6{
            font-weight: 500;
            font-size: 14px;
            color: #8E8E8E;
         }
         button{
          background: #C3EE83;
            border-radius: 5px;
            border: none;
            outline: none;
            cursor: pointer;
            padding: 2px 3px;
            font-weight: 400;
            font-size: 12px;
            color: #489019;
            display: flex;
            align-items: center;
            margin-left: 20px;
         }
         #arrow_btn{
          background: #FE9F99;
          color: #B52A54;
            padding: 2px 3px;
         }
      }
      .admin_Chart{
          margin: 30px 0 15px 0;
      }
 
      .Newest_title{
         display: flex;
         justify-content: space-between;
         align-items: center;
         height: 78px;
         h3{
             font-weight: 700;
             font-size: 18px;
             color: #2C2C2C;
         }
         .see_more_btn{
            padding: 8px 12px;
            background: #18498B;
           
            box-sizing: border-box;
            border-radius: 4px;
            border: none;
            outline: none;
            cursor: pointer;
            font-weight: 700;
            font-size: 14px;
            color: #FFFFFF;
            i{
               color: #FFFFFF;
            }
         }
      }
 }
 