.personal_info {
    max-width: 940px;
    width: 100%;
    margin: 0 auto;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(217, 217, 217, 0.5);
    border-radius: 5px;
    padding: 25px 30px;
    padding-bottom: 85px;
}

.content_title {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    width: 250px;
    height: 36px;
    left: 170px;
    top: 198px;
}

hr {
    border: 1px solid #dbdbdb
}


.avatar_img {
    position: relative;
}


.avatar_img img {
    width: 100%;
    height: 80%;
    border-radius: 28%;
    margin: 30px 80px 0 0;
}

.avatar_img .edit_button button{
    position: absolute;
    right: 2px;
    bottom: -2px;
    width: 34px;
    height: 34px;
    background: #3A3A3A;
    border-radius: 50%;
    text-align: center;
    padding: 4px;
    border: 0;
    outline: 0;
}

.avatar_img .edit_button button i {
    width: 17px;
    height: 17px;
    color: #FFFFFF;
} 

.user_form{
    margin-top: 26px;
}

.inputs_group {
    margin-right: 80px;
    margin-bottom: 16px;
}

.user_form form label {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #230505;
    text-align: left !important;
    margin-bottom: 12px;
}

.first_name input, .spiritual_name input, .middle_name input, .birth_date input, .last_name input, .user_email select, .user_phone input, .user_state select, .user_city select, .user_zipCode select, .user_country select {
    max-width: 690px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #8C8C8C;
}

select#email, select#state,select#zipCode,select#city,select#country {
    margin-left: 11px;
}

.gender_button {
    display: flex;
}

.gender_info {
    display: flex;
    align-items: center;
    width: unset;
}

input#gender {
    margin-left: 0;
    margin-right: 8px;
    width: unset;
}

#male_gender, #female_gender {
    margin: 0;
}

.street_address input, .address_optional input {
    max-width: 690px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #8C8C8C;
}

.css-f6v4u4-MuiInputBase-root-MuiFilledInput-root:after {
    border-bottom: 2px solid #1976d200 !important;
}
.css-f6v4u4-MuiInputBase-root-MuiFilledInput-root:before {
    border-bottom: 1px solid rgba(64, 37, 37, 0) !important;
    
}
#ful_Width{
    width: 100% !important;
    padding: 10px !important;
}
.my_place_pass ::placeholder{
   line-height: 1.7;
   display: inline-block !important;
   margin-bottom: 20px !important;
}

.has-error {
    border: 1px solid red !important;
  }