.created_course{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 0px !important;
    margin: 15px 0 0 0;
}

.created_course h3{
    font-weight: 400;
    font-size: 20px;
    color: #333333;
 }

 .created_course .see_more_btn{
    padding: 8px 12px;
    background: #18498B;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 4px;
    border: none;
    outline: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    color: #FFFFFF;

 }

 .created_course h3 {
   font-weight: 400;
   font-size: 18px;
   color: #333333;
 }

 .created_course .see_more_btn i{
    color: #FFFFFF;
 }

 .row {
	margin-right: 0;
}

 .timeline_card{
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    border: none;
    cursor: pointer;
 }
 
 .timeline_title{
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
    color: #2C2C2C;
 }
 .timeline_parent{
    display: flex;
 }

 .timeline_parent h6{
    font-weight: 500;
    font-size: 14px;
    color: #8E8E8E;
 }
 .timeline_parent button{
    background: #27AE60;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 2px 4px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    margin-left: 20px;
 }
 #arrow_btn{
    background: #F2994A;
    padding: 2px 4px;
 }
 .inner_timeline_progress{
    padding: 15px;
 }
 
 .progress_title{
    margin-top: 10px;
 }

 .progress_title h3{
    font-weight: 400;
    font-size: 20px;
    color: #333333;

 }

 .progress_cards{
    padding-top: 20px;
 }

 .progress_all_card{
     margin-left: 8px;
    border-radius: 10px !important;
    overflow: hidden !important;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    cursor: pointer;
 }

 .CourseCard_info {
    display: flex;
    justify-content: space-between;
 }


 .CourseCard_info  .card_title{
    color: #230505;
    font-weight: 400;
    font-size: 16px;
 }

 .CourseCard_info span {
    color: #333333;
 }

 .contentPanel  {
   margin-top: 24px;
   box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
}